import {
  MultipleThrowEvent,
  SimulatedThrowEvent,
  SimulatorEvent,
  SimulatorEventType,
  SimulatorSettingsEvent,
  SingleThrowEvent,
} from "@/types/UnityEvents";
import { Fullscreen } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Subject, distinctUntilChanged, tap } from "rxjs";
import { FlightPathParams } from "../../dashboard/Simulate3D";
import { firebaseApp } from "../../firebaseConfig";
import useTrackSubscription from "../../hooks/useTrackSubscription";
import { ThrowSummaryAndId } from "../../latestDashboard";
import { config, mobileConfig } from "../../simulator/utils";
import { getTrueUserId } from "../../summaryUtils";
import { TrackPoint } from "../../trackUtils";
import { SimulatorMode } from "../Simulator";

declare global {
  interface Window {
    receiveFPSValues?: (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => void;
    unityOnReady?: () => void;
  }
}

function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /ipad|android.*tablet|tablet.*android/i.test(userAgent);
  const isMobile = /iphone|android.*mobile|mobile.*android/i.test(userAgent);

  if (isTablet) {
    return "tablet";
  } else if (isMobile) {
    return "phone";
  } else {
    return "desktop";
  }
}
// Create a subject to act as the message queue
const messageQueue$ = new Subject<SimulatorEvent>();
const settingsMessageQueue$ = new Subject<SimulatorSettingsEvent>();

// Device detection utility
const getDeviceConfig = () => {
  const deviceType = detectDevice();
  if (deviceType === "phone" || deviceType === "tablet") {
    return mobileConfig;
  }
  return config;
};

interface UnityComponentProps {
  mode: SimulatorMode;
  userId: string;
  summary?: ThrowSummaryAndId;
  recentThrows?: ThrowSummaryAndId[];
  track?: TrackPoint[];
  flightPaths?: FlightPathParams[];
  buildName?: string;
}

export function UnityComponent(props: UnityComponentProps) {
  const { recentThrows, summary, flightPaths, userId, mode } = props;
  const {
    unityProvider,
    sendMessage,
    requestFullscreen,
    unload,
    initialisationError,
    isLoaded,
    UNSAFE__unityInstance,
  } = useUnityContext(getDeviceConfig());
  const [ready, setReady] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { backswing } = useTrackSubscription({
    userId,
    throwId: recentThrows?.[0]?.id,
    skip: !recentThrows?.[0]?.rotPerSec,
    type: "backswing",
  });

  useEffect(() => {
    if (isLoaded) {
      logEvent(getAnalytics(firebaseApp), "unity_loaded", { userId, client: "web" });
    } else {
      logEvent(getAnalytics(firebaseApp), "unity_request", { userId, client: "web" });
    }
  }, [isLoaded]);

  useEffect(() => {
    window.unityOnReady = () => {
      console.log("Unity is ready");
      setReady(true);
      logEvent(getAnalytics(firebaseApp), "unity_ready", { userId, client: "web" });
    };

    window.receiveFPSValues = (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => {
      // console.info("Average FPS:", averageFPS); // TODObrett muting this

      logEvent(getAnalytics(firebaseApp), "unity_perf", {
        sceneName: sceneName,
        averageFPS: averageFPS,
        percentile99: percentile99,
        percentile1: percentile1,
        client: "web",
        userId: getTrueUserId(),
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      try {
        if (isLoaded) {
          window.unityOnReady = undefined;
          window.receiveFPSValues = undefined;
          if (canvasRef.current) {
            canvasRef.current.remove();
          }
        }
        const exec = async () => {
          if (isLoaded) {
            await UNSAFE__unityInstance?.Quit();
            console.debug("Unity unloaded");
          }
        };
        exec();
      } catch (err) {
        console.error("Error unloading Unity:", err);
      }
    };
  }, [unload, isLoaded]);

  useEffect(() => {
    return () => {
      if (initialisationError) {
        console.error("Error initializing Unity:", initialisationError);
      }
    };
  }, [initialisationError]);

  useEffect(() => {
    if (ready) {
      const eventSubscription = messageQueue$
        .pipe(
          distinctUntilChanged((previous, current) => {
            const prevId = previous.correlationId;
            const currentId = current.correlationId;
            return JSON.stringify(prevId) === JSON.stringify(currentId);
          }),
          tap((message) => {
            if (window.location.origin !== "https://techdisc.com") {
              console.groupCollapsed("Throw Sent to Simulator");
              console.debug(message);
              console.groupEnd();
            }
            sendMessage("JavascriptHookManager", "SimulateEvent", JSON.stringify(message));
          }),
        )
        .subscribe({
          error: (err) => console.error("Error in combined message queue:", err),
        });

      const settingsSubscription = settingsMessageQueue$
        .pipe(
          distinctUntilChanged((previous, current) => {
            const prevId = previous.correlationId;
            const currentId = current.correlationId;
            return JSON.stringify(prevId) === JSON.stringify(currentId);
          }),
          tap((message) => {
            if (window.location.origin !== "https://techdisc.com") {
              console.groupCollapsed("Settings Change Requested");
              console.info(message);
              console.groupEnd();
            }
            sendMessage("JavascriptHookManager", "SettingsEvent", JSON.stringify(message));
          }),
        )
        .subscribe({
          error: (err) => console.error("Error in combined message queue:", err),
        });

      return () => {
        eventSubscription.unsubscribe();
        settingsSubscription.unsubscribe();
      };
    }
  }, [ready, sendMessage, mode]);

  useEffect(() => {
    // const adjust = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (ready && summary) {
      if (mode === "single") {
        messageQueue$.next({
          version: "1",
          correlationId: summary.id,
          event: SimulatorEventType.SingleThrow,
          data: { summary: summary.originalSummary, backswing },
          timeZone,
          timestamp: Date.now(),
        } as SingleThrowEvent);
      } else if (mode === "multiple") {
        messageQueue$.next({
          version: "1",
          correlationId: summary.id,
          event: SimulatorEventType.MultipleThrows,
          data: {
            summaries: recentThrows?.map((v) => v.originalSummary),
            // backswings: recentThrows?.map((v) => v.backswing),
          },
          timeZone,
          timestamp: Date.now(),
        } as MultipleThrowEvent);
      } else {
        messageQueue$.next({
          version: "1",
          correlationId: summary.id,
          event: SimulatorEventType.SimulatedThrow,
          data: { flightPath: [] },
          timeZone,
          timestamp: Date.now(),
        } as SimulatedThrowEvent);
      }
    }
  }, [backswing, ready, recentThrows, flightPaths, summary, mode]);

  return (
    <Stack
      gap={1}
      sx={{
        outline: "0px",
        width: "100%",
        aspectRatio: 16 / 9,
      }}
    >
      <Stack
        sx={{
          display: { mobile: "flex", sm: "none" },
          px: { mobile: 1, md: 2 },
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={() => requestFullscreen(true)}
          sx={{
            p: 0.5,
            background: "white",
            width: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
          }}
          variant="outlined"
        >
          <Fullscreen fontSize="small" />
          <Stack sx={{ fontSize: "0.8rem" }}>FULLSCREEN</Stack>
        </Button>
      </Stack>
      <Box
        id="unity-canvas"
        component={Unity}
        unityProvider={unityProvider}
        tabIndex={1}
        ref={canvasRef}
        matchWebGLToCanvasSize
        devicePixelRatio={window.devicePixelRatio}
        sx={{
          outline: "0px",
          width: "100%",
          aspectRatio: 16 / 9,
          backgroundColor: "grey.50",
        }}
      />
    </Stack>
  );
}
