import { Vector3 } from "three";
import { ThrowSummary } from "../src/model/throwSummary";
import { TrackPoint } from "../src/trackUtils";

export interface BaseEvent<TEventType extends UnityEventType, TEventData> {
  version: string;
  correlationId: string;
  event: TEventType;
  data: TEventData;
  timestamp: number;
}

export const enum SimulatorEventType {
  SimulatedThrow = "simulatedThrow",
  SingleThrow = "singleThrow",
  MultipleThrows = "multipleThrows",
}

export const enum SimulatorSettingsEventType {
  ChangeScene = "changeScene",
}

export type UnityEventType = SimulatorEventType | SimulatorSettingsEventType;

export type SimulatorEvent<TEventData extends Record<string, any> = object> = BaseEvent<
  SimulatorEventType,
  TEventData
> & {
  timeZone: string;
};

export type SimulatorSettingsEvent<TEventData extends Record<string, any> = object> = BaseEvent<
  SimulatorSettingsEventType,
  TEventData
>;

export interface SimulatedThrowEventData {
  flightPath: Vector3[];
}

export interface SingleThrowEventData {
  summary: ThrowSummary;
  backswing: TrackPoint[];
}

export interface MultipleThrowEventData {
  summaries: ThrowSummary[];
  // backswings: TrackPoint[];
}

export interface SimulatedThrowEvent extends SimulatorEvent<SimulatedThrowEventData> {
  event: SimulatorEventType.SimulatedThrow;
  data: SimulatedThrowEventData;
}

export interface SingleThrowEvent extends SimulatorEvent<SingleThrowEventData> {
  event: SimulatorEventType.SingleThrow;
  data: SingleThrowEventData;
}

export interface MultipleThrowEvent extends SimulatorEvent<MultipleThrowEventData> {
  event: SimulatorEventType.MultipleThrows;
  data: MultipleThrowEventData;
}
