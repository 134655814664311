import { Spinner } from "@blueprintjs/core";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import { motion } from "framer-motion";
import Plyr from "plyr";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../components/Section";
import Simulator from "../components/Simulator";
import TagManager from "../components/TagManager";
import { Medium, Text } from "../components/Text";
import CoreMetricsComponent from "../components/flight/CoreMetrics";
import { XYFlightChart } from "../components/flight/XYFlightChart/XYFlightChart";
import RouteLayout from "../components/layout/RouteLayout";
import { useGlobal } from "../components/providers/GlobalProvider";
import { containerVariants, itemVariants } from "../components/routes/variants";
import ThrowsTable from "../components/tables/ThrowsTable";
import { firebaseApp } from "../firebaseConfig";
import useLive from "../hooks/useLive";
import useLocalStorage from "../hooks/useLocalStorage";
import useStorage from "../hooks/useStorage/useStorage";
import { useUser } from "../hooks/useUser";
import { SetTags } from "../latestDashboard";
import { Handedness } from "../model/UserSettings";
import { Device } from "../model/device";
import { ThrowSummary } from "../model/throwSummary";
import { loadDevices } from "../summaryUtils";
import { TrackPoint } from "../trackUtils";
import { div, mul } from "../utils/math";
import Tags from "./DashboardTags";
import Note from "./Note";
import { ThrowDetails } from "./ThrowDetails";
import Title from "./Title";
import { getStorageRef } from "./dashboardUtils";

export function ThrowMetrics(props: { throwSummary: ThrowSummary; metricsLoading: boolean }) {
  const { userSettings } = useGlobal();
  const { throwSummary, metricsLoading } = props;

  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: {
          tablet: "1fr 3fr",
          mobile: "1fr",
        },
        gap: { mobile: 1.5, md: 2 },
      }}
    >
      <ThrowDetails
        title="Latest Throw"
        handedness={throwSummary?.handedness ?? userSettings?.handedness ?? Handedness.RIGHT}
        throwSummary={throwSummary}
      />
      <CoreMetricsComponent metricsLoading={metricsLoading} summary={throwSummary} />
    </Stack>
  );
}

export const [trackChange$, setTrack] = createSignal<TrackPoint[] | null>();
export const [useTrack, track$] = bind(trackChange$, null);

export const [estimatedFeetChange$, setEstimatedFeet] = createSignal<number | null>();
export const [useEstimatedFeet, estimatedFeet$] = bind(estimatedFeetChange$, null);

export const [flightPathChange$, setFlightPath] = createSignal<THREE.Vector3[] | null>();
export const [useFlightPath, flightPath$] = bind(flightPathChange$, null);

function DashboardContent(props: {
  isThrowSet?: boolean;
  throws: ThrowSummary[];
  throwLoading: boolean;
  flightPathLoading: boolean;
  setLockedTags: SetTags;
  singleDoc: boolean;
  devices: Map<string, Device>;
  userId: string;
  isDebugThrows: boolean;
}) {
  const {
    isThrowSet,
    throws,
    throwLoading,
    flightPathLoading,
    setLockedTags,
    singleDoc,
    devices,
    userId,
    isDebugThrows,
  } = props;
  const [{ trueUserId }] = useUser();
  const { isDeveloper, isSupport } = useGlobal();
  const { isLiveRoute } = useLive();
  const [latestThrow] = throws;

  const frameRateBasedScrubOffset = useMemo(
    () => div(6, mul(latestThrow.videoMetadata?.coordinator.frameRate ?? 60, 6)).toNumber(),
    [latestThrow.videoMetadata?.coordinator.frameRate],
  );

  const { url: throwVideoUrl } = useStorage({
    storageRef:
      !isDeveloper || !latestThrow.videoMetadata
        ? void 0
        : getStorageRef(latestThrow.videoMetadata?.coordinator.fullFilePath),
    skip: !isDeveloper || !latestThrow.videoMetadata,
  });

  const player = new Plyr("#latest-throw-video", {
    controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "captions"],
    storage: {
      enabled: true,
      key: "latest-throw-video",
    },
  });

  return (
    <RouteLayout containerVariants={containerVariants}>
      <Container
        sx={{
          mt: { mobile: 2, md: 4 },
          mb: 4,
          display: "flex",
          flexDirection: "column",
          gap: { mobile: 1.5, md: 2 },
        }}
      >
        <Section itemVariants={itemVariants}>
          <ThrowMetrics metricsLoading={throwLoading} throwSummary={latestThrow} />
        </Section>
        <Section itemVariants={itemVariants}>
          <FlightPathCard flightPathLoading={flightPathLoading} throws={throws} userId={userId} />
        </Section>
        {isDeveloper && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyItems="center"
              gap={2}
              sx={{ width: "100%" }}
            >
              <Medium>Seek</Medium>
              <Button onClick={() => player.rewind(frameRateBasedScrubOffset)}>
                <ArrowLeft />
              </Button>
              <Button onClick={() => player.forward(frameRateBasedScrubOffset)}>
                <ArrowRight />
              </Button>
            </Stack>
            <Box
              id="latest-throw-video"
              component="video"
              src={throwVideoUrl}
              sx={{ aspectRatio: "4/3" }}
              controls={true}
            />
          </>
        )}
        {(trueUserId === userId || isSupport) && !isLiveRoute && throws.length && (
          <Section itemVariants={itemVariants}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Note userId={userId} throwSummary={latestThrow} />
            </Paper>
          </Section>
        )}
        {(trueUserId === userId || isSupport) && throws.length && (
          <Section itemVariants={itemVariants}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tags
                userId={userId}
                isDashboard={!singleDoc}
                value={latestThrow}
                setLockedTags={setLockedTags}
              />
            </Paper>
          </Section>
        )}
        {!singleDoc && (
          <Section itemVariants={itemVariants}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Title variant="secondary">{isThrowSet ? "Throws" : "Recent Throws"}</Title>
              <ThrowsTable throws={throws} id={"dashboard"} devices={devices} display="compact" />
            </Paper>
          </Section>
        )}

        {/* {[
          latestThrow.deviceId === "ec:d1:5c:b2:52:3e",
          latestThrow.deviceId === "ec:d1:5c:c9:80:1d",
          isDeveloper,
          false, // remove to display for devs
        ].every(Boolean) && ( // change back to .some() to display for devs
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title>Each throw is a mini wind tunnel test and adds another data point.</Title>
            <DeviceCharts deviceId={latestThrow.deviceId} />
          </Paper>
        )}
        {isDeveloper && (
          <Paper
            sx={{
              p: 2,
              display: "flex",
              width: "150%",
              marginLeft: "-25%",
              flexDirection: "column",
            }}
          >
            <FourPanelFlight {...latestThrow} />
          </Paper>
        )} */}
        {/* {isDebugThrows && <DeveloperFeatures {...props} />} */}
      </Container>
    </RouteLayout>
  );
}

const Dashboard = (props: {
  userId: string;
  throws: ThrowSummary[];
  noThrowComponent?: JSX.Element;
  singleDoc?: boolean;
  setLockedTags?: SetTags;
}) => {
  const { throws, noThrowComponent, singleDoc, userId } = props;
  const [{ trueUserId }] = useUser();
  const [devices, setDevices] = useState(new Map());
  const { isSupport, isDebugThrows } = useGlobal();

  // TODO: replace this useEffect with firebase useCollection
  useEffect(() => {
    let isCancelled = false;
    if ((userId && trueUserId === userId) || isSupport) {
      loadDevices(userId).then((devices) => {
        if (!isCancelled) {
          setDevices(devices);
        }
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [userId, trueUserId, isSupport]);

  if (throws.length === 0) {
    return (
      noThrowComponent ?? (
        <Container sx={{ my: 4, mx: "0 auto", textAlign: "center" }}>
          <Title>No throws found.</Title>
        </Container>
      )
    ); // quick fix for giving some padding
  }
  const [latestThrow] = throws;

  const throwLoading = !!latestThrow.loading;
  const flightPathLoading = !latestThrow?.hasFlight && !latestThrow?.estimatedFeet;

  const filtered = throws.filter((v) => !v.loading);

  if (filtered.length === 0) {
    return <Spinner />;
  }
  return (
    <DashboardContent
      isDebugThrows={isDebugThrows}
      throws={filtered}
      throwLoading={throwLoading}
      flightPathLoading={flightPathLoading}
      userId={userId}
      devices={devices}
      singleDoc={singleDoc || false}
      setLockedTags={(tags: string[]) => {
        TagManager.setLockedTags(userId, tags);
      }}
    />
  );
};

export default Dashboard;

export interface FlightPathProps {
  throws: ThrowSummary[];
  flightPathLoading?: boolean;
  userId: string;
}

const FlightPathCard = React.memo(function FlightPathCard(props: FlightPathProps) {
  const { userId, throws, flightPathLoading } = props;
  const isIOS = /iphone|ipad/i.test(navigator.userAgent);
  const [simPerspective, setSimPerspective] = useLocalStorage(
    "simPerspective",
    isIOS ? "2d" : "3d",
  );
  const handleChangePerspective = (perspective: "2d" | "3d") => {
    logEvent(getAnalytics(firebaseApp), "select_perspective", { perspective });
    setSimPerspective(perspective);
  };
  const { t } = useTranslation();
  const simulatorView = useMemo(() => simPerspective === "3d", [simPerspective]);
  const flightChartView = useMemo(() => simPerspective === "2d", [simPerspective]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [recentThrow] = throws;
  const [latestThrow, setLatestThrow] = useState<ThrowSummary>(recentThrow);

  useEffect(() => {
    if (!flightPathLoading && recentThrow.distanceFeet) {
      setLatestThrow(recentThrow);
    }
  }, [flightPathLoading, recentThrow]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        gap={1}
        component={motion.div}
        initial={{ maxHeight: flightChartView ? 600 : 900 }}
        animate={{ maxHeight: flightChartView ? 600 : 900 }}
        transition={{ duration: 0.5 }}
        sx={{ p: { mobile: simulatorView ? 1 : 0, sm: 0 } }}
      >
        {/*<WebsocketComponent summary={recentThrow} />*/}
        {flightChartView ? (
          <>
            <Stack
              alignItems="center"
              sx={{
                my: 2,
                px: flightChartView
                  ? { mobile: 1, md: 2 }
                  : simulatorView
                    ? { mobile: 1, md: 2 }
                    : 2,
                pt: flightChartView
                  ? { mobile: 1, md: 2 }
                  : simulatorView
                    ? { mobile: 1, md: 2 }
                    : 2,
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                gap={2}
                sx={{ opacity: flightPathLoading ? 0.5 : 1 }}
              >
                <Stack direction="row" gap={0.5} alignItems="center">
                  <svg
                    width={isMobile ? "16" : "20"}
                    height={isMobile ? "16" : "20"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polygon
                      points={isMobile ? "2,14 8,2 14,14" : "2,18 10,2 18,18"}
                      style={{
                        fill: "white",
                        stroke: theme.palette.primary.main,
                        strokeWidth: isMobile ? "2px" : "3px",
                      }}
                    />
                  </svg>
                  <Typography variant={isMobile ? "caption" : "body2"}>
                    {t("metrics.apex.full")}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <svg
                    width={isMobile ? "16" : "20"}
                    height={isMobile ? "16" : "20"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      r={isMobile ? 6 : 8}
                      cx={isMobile ? 8 : 10}
                      cy={isMobile ? 8 : 10}
                      fill={theme.palette.primary.main}
                      stroke={theme.palette.primary.main}
                      strokeWidth={3}
                    />
                  </svg>
                  <Typography variant={isMobile ? "caption" : "body2"}>
                    {t("metrics.distance.full")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box>
              <Stack
                px={{ md: 2, mobile: 1 }}
                mb={2}
                sx={{
                  minHeight: { mobile: "225px", md: "375px" },
                  opacity: flightPathLoading ? 0.5 : 1,
                }}
              >
                <XYFlightChart userId={userId} throwSummary={latestThrow} />
              </Stack>
            </Box>
          </>
        ) : (
          <Stack
            alignItems={"flex-end"}
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Simulator mode="single" userId={userId} flights={throws} />
          </Stack>
        )}
        <Stack alignItems="center" justifyContent="center" gap={2} sx={{ py: 2 }}>
          <Stack direction="row" alignItems="center" gap={4}>
            <Button
              component={motion.button}
              whileHover={
                simulatorView
                  ? {
                      scale: 1.05,
                      transition: { duration: 0.5 },
                    }
                  : void 0
              }
              size="medium"
              variant={flightChartView ? "primary" : "secondary"}
              onClick={() => handleChangePerspective("2d")}
            >
              {t("flightPath_capital_one")}
            </Button>
            <Button
              component={motion.button}
              whileHover={
                flightChartView
                  ? {
                      scale: 1.05,
                      transition: { duration: 0.5 },
                    }
                  : void 0
              }
              transition={{ duration: 0.5 }}
              variant={simulatorView ? "primary" : "secondary"}
              color="primary"
              size="medium"
              onClick={() => handleChangePerspective("3d")}
            >
              {t("simulator_capital_one")}
            </Button>
          </Stack>
          <Stack direction="row" gap={0.5}>
            <Text color="grey.600" size={14}>
              {t("flightPath.feedback.controls")}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
});
